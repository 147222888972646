import * as actionList from '../actions/tasklistactions';
import moment from 'moment';
const state = {
    search: '',
    userId: null,
    year: moment(new Date(), "YYYY").toDate().getFullYear(),
    week: moment(new Date(), "MMDDYYYY").isoWeek()
};

const getters = {
    search: state => state.search,
    userId: state => state.userId,
    year: state => state.year,
    week: state => state.week,
};

const actions = {
    [actionList.TASKLIST_SEARCH]: ({ commit }, search) => {
        commit(actionList.TASKLIST_SEARCH, search);
    },
    [actionList.TASKLIST_USERID]: ({ commit }, userId) => {
        commit(actionList.TASKLIST_USERID, userId);
    },
    [actionList.TASKLIST_YEAR]: ({ commit }, year) => {
        commit(actionList.TASKLIST_YEAR, year);
    },
    [actionList.TASKLIST_WEEK]: ({ commit }, week) => {
        commit(actionList.TASKLIST_WEEK, week);
    },
};

const mutations = {
    [actionList.TASKLIST_SEARCH]: (state, search) => {       
        state.search = search;
    },
    [actionList.TASKLIST_USERID]: (state, userId) => {        
        state.userId = userId;
    },
    [actionList.TASKLIST_YEAR]: (state, year) => {        
        state.year = year;
    },
    [actionList.TASKLIST_WEEK]: (state, week) => {        
        state.week = week;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};