import listmodule from './list.js';
import { TASKLIST_USERID } from '../actions/companytasklistactions.js';

const state = {
    taskuserId: null,
    ...listmodule.state
};

const getters = {
    taskuserId: state => state.taskuserId,
    ...listmodule.getters
};

const actions = {
    [TASKLIST_USERID]: ({ commit }, taskuserId) => {
        commit(TASKLIST_USERID, taskuserId);
    },
    ...listmodule.actions
};

const mutations = {
    [TASKLIST_USERID]: (state, taskuserId) => {
        state.page = 0;
        state.taskuserId = taskuserId;
    },
    ...listmodule.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};