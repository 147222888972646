import Vue from 'vue';
import i18n from './i18n.js';
import moment from 'moment';
import 'moment-timezone';

const monthNamesShort = [
    i18n.t('periods.januaryShort'),
    i18n.t('periods.februaryShort'),
    i18n.t('periods.marchShort'),
    i18n.t('periods.aprilShort'),
    i18n.t('periods.mayShort'),
    i18n.t('periods.juneShort'),
    i18n.t('periods.julyShort'),
    i18n.t('periods.augustShort'),
    i18n.t('periods.septemberShort'),
    i18n.t('periods.octoberShort'),
    i18n.t('periods.novemberShort'),
    i18n.t('periods.decemberShort'),
];

const monthNames = [
    i18n.t('periods.january'),
    i18n.t('periods.february'),
    i18n.t('periods.march'),
    i18n.t('periods.april'),
    i18n.t('periods.may'),
    i18n.t('periods.june'),
    i18n.t('periods.july'),
    i18n.t('periods.august'),
    i18n.t('periods.september'),
    i18n.t('periods.october'),
    i18n.t('periods.november'),
    i18n.t('periods.december'),
];
const months = [
    '',
    i18n.t('periods.january'),
    i18n.t('periods.february'),
    i18n.t('periods.march'),
    i18n.t('periods.april'),
    i18n.t('periods.may'),
    i18n.t('periods.june'),
    i18n.t('periods.july'),
    i18n.t('periods.august'),
    i18n.t('periods.september'),
    i18n.t('periods.october'),
    i18n.t('periods.november'),
    i18n.t('periods.december'),
];
function formatPeriod(period, short) {
    if(period.isYear) {
        return short ? i18n.t('periods.yearDeclarationShort'): i18n.t('periods.yearDeclaration')+' '+period.year;
    }
    var startMonth = parseInt(moment(period.periodStartDate).tz('UTC').format('M'));
    var endMonth = parseInt(moment(period.periodEndDate).tz('UTC').format('M'));
    if(startMonth == endMonth) {
        return short ? monthNamesShort[startMonth-1]: monthNames[startMonth-1]+' '+period.year;
    }
    else if(startMonth == 1 && endMonth == 12) {
        return period.year;
    }
    else if(startMonth == 1 && endMonth == 6) {
        return short ? i18n.t('periods.firstHalfYear'): i18n.t('periods.firstHalfYear')+' '+period.year;
    }
    else if(startMonth == 7 && endMonth == 12) {
        return short ? i18n.t('periods.secondHalfYear'): i18n.t('periods.secondHalfYear')+' '+period.year;
    }
    else if(startMonth == 1 && endMonth == 3) {
        return short ? i18n.t('periods.quarter1Short'): i18n.t('periods.quarter1')+' '+period.year;
    }
    else if(startMonth == 4 && endMonth == 6) {
        return short ? i18n.t('periods.quarter2Short'): i18n.t('periods.quarter2')+' '+period.year;
    }
    else if(startMonth == 7 && endMonth == 9) {
        return short ? i18n.t('periods.quarter3Short'): i18n.t('periods.quarter3')+' '+period.year;
    }
    else if(startMonth == 10 && endMonth == 12) {
        return short ? i18n.t('periods.quarter4Short'): i18n.t('periods.quarter4')+' '+period.year;
    } 
    else {
        return short ? `${monthNamesShort[startMonth-1]} - ${monthNamesShort[endMonth-1]}`: `${monthNames[startMonth-1]} - ${monthNames[endMonth-1]}`+' '+period.year;
    }
}

Vue.filter('date', value => value == null ? '' : moment(value).tz('UTC').format(i18n.t('format.date')));
Vue.filter('dateoriginal', value => value == null ? '' : moment(value).format(i18n.t('format.date')));
Vue.filter('dateTime', value=> value == null? '' : moment(value).tz('UTC').format(i18n.t('format.dateTime')));
Vue.filter('periodShort', value=> formatPeriod(value, true));
Vue.filter('period', value=> formatPeriod(value, false));
Vue.filter('commalist', value => value.split(',').join(', '));
Vue.filter('money', (value, currency) => !value?'-': value.toLocaleString(i18n.t('culture'), { style: 'currency', currency: currency || 'EUR', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
Vue.filter('decimal', value => !value?'-': value.toLocaleString(i18n.t('culture'), { style: 'decimal', useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 }));
Vue.filter('int', value => !value?'-': value.toLocaleString(i18n.t('culture'), { style: 'decimal', useGrouping: true, maximumFractionDigits: 0, minimumFractionDigits: 0 }));
Vue.filter('declarationTypeDescription', value => {
    switch(value) {
    case 0:
        return i18n.t('vat');
    case 1:
        return i18n.t('intrastat');
    case 2:
        return i18n.t('icp');
    case 3:
        return i18n.t('customerlisting');
    }
    return '';
});
Vue.filter('refundTypeDescription', value => {
    switch(value) {
    case 0:
        return i18n.t('vatrefund');
    }
    return '';
});
Vue.filter('exciseTypeDescription', value => {
    switch(value) {
    case 0:
        return i18n.t('excise');
    }
    return '';
});
Vue.filter('periodf', (start, end) => {
    if (!start || !end) return '';
    let startObj = moment(start).tz(i18n.t('format.timezone'));
    let endObj = moment(end).tz(i18n.t('format.timezone'));
    if (startObj.format('M') == endObj.format('M'))
        return months[startObj.format('M')] + ' ' + startObj.format('Y');
    if (startObj.format('Y') == endObj.format('Y'))
        return months[startObj.format('M')] + ' - ' + months[endObj.format('M')] + ' ' + startObj.format('Y');
    return months[startObj.format('M')] + ' ' + startObj.format('Y') + ' - ' + months[endObj.format('M')] + ' ' + endObj.format('Y');
});
Vue.filter('dateheader', (date) => {
    if (!date) return '';
    let startObj = moment(date).tz(i18n.t('format.timezone'));    
    return startObj.format('D') + ' ' + months[startObj.format('M')].toLowerCase();
});