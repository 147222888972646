var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select2", {
    class: _vm.inputclass,
    attrs: {
      options: _vm.options,
      value: _vm.value,
      settings: {
        width: "100%",
        allowClear: _vm.emptyname != null,
        placeholder: _vm.emptyname
      }
    },
    on: { change: _vm.change }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }